<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form label-width="8rem">
        <el-form-item label="apiclient_cert.pem">
          <y_upload_cert @load="load" mode="cert" v-model="cert"></y_upload_cert>
        </el-form-item>
        <el-form-item label="apiclient_key.pem">
          <y_upload_cert @load="load" mode="key" v-model="key"></y_upload_cert>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import y_upload_cert from "@/components/y_upload_cert";
export default {
  name: "cert",
  components:{
    y_upload_cert
  },
  data(){
    return{
      cert:false,
      key:false
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    copy(){
      this.$message.success("复制成功");
    },
    load(){
      this.$api.channel.payCert().then(res=>{
        this.cert = res.cert;
        this.key = res.key;
      })
    },
    edit(){
      this.$api.account.uniEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  },
  computed:{
    paymentScope(){
      return location.origin + "/app/"
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>