<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-form v-loading="loading" :model="form" label-width="8rem">
        <el-form-item label="支付商户号">
          <el-input v-model="form.wx_pay_mch_id"></el-input>
        </el-form-item>
        <el-form-item label="支付密钥">
          <el-input v-model="form.wx_pay_key"></el-input>
        </el-form-item>
        <el-form-item label="支付证书序列号">
          <el-input v-model="form.wx_pay_serial_no"></el-input>
        </el-form-item>
        <el-form-item label="支付V3密钥">
          <el-input v-model="form.wx_pay_api_v3_key"></el-input>
        </el-form-item>
        <el-form-item label="微信打款方式">
          <el-radio-group v-model="form.wx_cash_pay_func">
            <el-radio :label="0">企业付款到零钱</el-radio>
            <el-radio :label="1">商家转账到零钱</el-radio>
          </el-radio-group>
          <div class="y-desc" style="color: red">站点运营期间请勿随意变更此项操作</div>
          <div class="y-desc" style="color: red">财务资金类操作请自行核对测试，确保预期与程序执行一直</div>
        </el-form-item>
        <el-form-item >
          <el-button @click="edit" type="primary">接入检测</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="12">
      <div class="y-pointer">
        <div class="y-desc" style="font-weight: unset">支付授权目录</div>
        <div @click="copy" v-clipboard:copy="paymentScope" style="margin-top: .5rem">
          <span style="font-size: 14px;color: #606266;">
            <span>{{paymentScope}}</span>
          </span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data(){
    return{
      form:{
        wx_pay_mch_id:"",
        wx_pay_key:"",
        wx_pay_serial_no:"",
        wx_pay_api_v3_key:"",
        wx_cash_pay_func: 0,//0企业付款到零钱 1商家转账到零钱
      },
      loading:false
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    copy(){
      this.$message.success("复制成功");
    },
    load(){
      this.loading = true;
      this.$api.account.info().then(res=>{
        this.form = res.uni_account
        this.loading = false;
      })
    },
    edit(){
      this.$api.account.uniEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  },
  computed:{
    paymentScope(){
      return location.origin + "/"
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
</style>